@import url("https://fonts.googleapis.com/css2?family=Albert+Sans:wght@200;300;400;500;600;700&display=swap");
body {
  margin: 0;
  box-sizing: border-box;
  font-family: "Albert Sans", sans-serif;
}
a,
a:hover {
  color: inherit;
  text-decoration: none;
}
.span-yellow {
  color: #e8ae1b;
}
.mt-20 {
  margin-top: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mt-50 {
  margin-top: 50px;
}
.w-100 {
  width: 100%;
}
.position-relative {
  position: relative;
}
.start-but-center {
  display: flex;
  justify-content: flex-start;
}
.end-but-center {
  display: flex;
  justify-content: flex-end;
}
.border-yellow {
  border: 1px solid #e8ae1b;
  padding: 20px 10px;
  margin-bottom: 30px;
}
.error-border {
  border: 1px solid rgb(243, 81, 81) !important;
  outline: 1px solid rgb(243, 81, 81) !important;
}
.login-container {
  width: 100%;
  height: calc(100vh - 90px);
}
.login-row {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.action-btn {
  background-color: #e8ae1b;
  padding: 15px 23px;
  text-align: center;
  font-size: 18px;
  letter-spacing: -0.4px;
  font-weight: 600;
  width: 100%;
  border-radius: 5px;
  transition: 0.3s;
  border: none;
  margin-bottom: 30px;
}
.action-btn-2 {
  background-color: #e8ae1b;
  padding: 15px 23px;
  text-align: center;
  font-size: 18px;
  letter-spacing: -0.4px;
  font-weight: 600;
  width: 100px;
  border-radius: 5px;
  transition: 0.3s;
  border: none;
}
.loader-container {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader-container-2 {
  height: 100vh;
  width: 100%;
  position: absolute;
  z-index: 10;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader-mini-container {
  height: 20vh;
  width: 100%;
  position: absolute;
  z-index: 10;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader-mini {
  border: 8px solid black;
  border-radius: 50%;
  border-top: 8px solid #e8ae1b;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}
.loader {
  border: 8px solid black;
  border-radius: 50%;
  border-top: 8px solid #e8ae1b;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}
.loader-2 {
  border: 8px solid #e8ae1b;
  border-radius: 50%;
  border-top: 8px solid white;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.opacity-01 {
  opacity: 1;
}
.opacity-03 {
  opacity: 0.3;
}
.login-img {
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #e8ae1b;
  display: flex;
  align-items: center;
  justify-content: center;
}
.big-heading {
  font-size: 4.5rem;
  font-weight: bold;
  letter-spacing: -5px;
  text-align: left;
  margin: 50px 0px;
  color: black;
}
.login-padding {
  padding: 40px 0px;
  margin-left: -50px;
}
.login-screen {
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgb(0 0 0 / 25%);
}
.login-heading {
  font-size: 40px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 30px;
}
.input-com-group {
  margin-bottom: 30px;
}
.text-editer{
  height: 500px;
  margin-bottom: 100px;
}
.label-class {
  font-family: "Albert Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: black;
  margin-bottom: 10px;
}
.input-group {
  /* background-color: #f4f4f4; */
  background-color: #ffffff;
  border: none;
  outline: 1px solid #bfbfbf;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
  padding: 15px;
  color: black;
  transition: 0.3s;
}
.input-group:focus-visible,
.input-group:focus,
.input-group:focus-within {
  outline: 1px solid black !important;
}
.dash-h1 {
  font-size: 60px;
  font-weight: bold;
  letter-spacing: -5.4px;
  margin-bottom: 0px;
  line-height: 80px;
}
.alert-box {
  background-color: white;
  box-shadow: 0px 0px 15px rgb(0 0 0 / 1%);
  border-radius: 10px;
  padding: 20px 15px;
  display: flex;
  align-items: flex-start;
  border: 1px solid #e8ae1b;
  margin-bottom: 40px;
}
.alert-box .alert-icon {
  background-color: rgba(232, 174, 27, 0.3);
  border-radius: 50%;
  padding: 15px;
}
.alert-box .alert-icon .alert-img {
  width: 30px;
}
.alert-box .alert-text {
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 0px;
  text-align: left;
  margin-left: 20px;
  color: black;
}
.team-section {
  background-color: rgba(55, 162, 208, 0.1);
  border-radius: 10px;
  padding: 20px;
}
.table-fix {
  display: table !important;
}
.search-icon {
  position: absolute;
  right: 10px;
  bottom: 16px;
  opacity: 0.5;
  width: 20px;
}
.table {
  background-color: white;
  border-radius: 10px;
}
.table-img-container {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
}
.table-img-container .table-img {
  width: 100%;
}
.table td,
.table th {
  vertical-align: middle !important;
  border-top: none !important;
}
.table thead th {
  border-bottom: none !important;
  font-size: 14px;
}
.user-detail-section {
  display: flex;
  flex-direction: column;
}
.user-box {
  background-color: white;
  box-shadow: 0px 0px 15px rgb(0 0 0 / 8%);
  border-radius: 10px;
  padding: 20px;
  margin: 10px 0px;
  width: 100%;
}
.user-box .user-role {
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 0px;
  text-align: left;
  color: black;
}
.user-box .roles {
  font-size: 24px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 0px;
  text-align: left;
  color: black;
}
.add-bg-image {
  width: 100%;
  background-color: #f4f4f4;
  border: 1px solid #bfbfbf;
  height: 260px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-bg-image input[type="file"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
}
.project-img-container {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #e8ae1b;
}
.z-3 {
  z-index: 3;
}
.z-2 {
  z-index: 2;
}
.second-img {
  left: -20px;
  position: relative;
}
.project-detail-title {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 10px;
  text-align: left;
  color: black;
  /* opacity: 0.5; */
}
.project-detail-info {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0px;
  text-align: left;
  color: black;
  display: flex;
  align-items: center;
}
.check {
  background-color: white;
  padding: 20px 30px;
  border-radius: 10px;
}
.warning-text {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0px;
  text-align: center;
  color: red;
}
.check .buttons {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 30px;
}

.check .buttons .yes-btn {
  background-color: #e8ae1b;
  color: white;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 15px;
  outline: none;
  border: 1px solid #e8ae1b;
  border-radius: 5px;
  width: 100%;
  margin: 0px 5px;
}

.check .buttons .no-btn {
  background-color: white;
  color: black;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 15px;
  outline: none;
  border: 1px solid #e8ae1b;
  border-radius: 5px;
  width: 100%;
  margin: 0px 5px;
}
.color-picker {
  position: absolute;
  bottom: 20px;
  right: 0px;
}
.color-circle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-top: -1px;
  margin-right: 10px;
}
.edit-image {
  width: 100%;
  position: relative;
}
.edit-icons {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  width: 32px;
}
.edit-icons .delete {
  width: 32px;
  cursor: pointer;
  margin-bottom: 20px;
}
.edit-icons .edit {
  width: 32px;
  cursor: pointer;
}
.file-input {
  position: absolute;
  top: 40px;
  right: 0px;
}
.border-yellow-2 {
  border: 1px solid #e8ae1b;
}

.toolbar {
  position: sticky;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 10px 35px 0px rgba(0, 0, 0, 0.07);
  height: 70px;
  top: 0px;
  left: 0px;
  z-index: 899;
}
.toolbar .toolbar-navigation {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 10px 25px;
}
.toolbar .toolbar-navigation-items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}
.toolbar .toolbar-navigation-items li {
  padding: 5px 15px;
}
.toolbar .toolbar-navigation-items li img {
  margin-left: 10px;
  width: 21px;
}
.toolbar .toolbar-logo {
  margin-left: 10px;
}
.toggle-button {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  border: none;
  cursor: pointer;
}
.toggle-button:focus {
  outline: none;
}
.toolbar .toolbar-logo a {
  text-decoration: none;
}
.toolbar .toolbar-navigation-items a {
  text-decoration: none;
  color: black;
  font-size: 14px;
  font-weight: 300;
  transition: 0.3s;
  border-bottom: 3px solid #fff;
}
.toolbar .toolbar-navigation-items a:hover,
.toolbar .toolbar-navigation-items a:active {
  border-bottom: 3px solid #e8ae1b;
  /* color:#00D1A1; */
  /* background:#0D5544; */
}
.side-drawer {
  height: 100%;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  max-width: 400px;
  z-index: 1000;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}
.side-drawer.open {
  transform: translateX(0px);
  box-shadow: 2px 0px 20px rgba(0, 0, 0, 0.43);
}
.side-drawer ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  /* justify-content: center; */
  height: 100%;
  padding: 0px 10px;
}
.cursor-pointer {
  cursor: pointer;
}
.side-drawer li {
  margin: 10px 0px;
  padding: 10px 8vh;
  width: 100%;
}
.side-drawer a {
  color: black;
  background: #fff;
  transition: 0.2s;
  border-radius: 3px;
}
.side-drawer a:hover {
  text-decoration: none;
  background: #e8ae1b;
  color: #fff;
  cursor: pointer;
}
.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 999;
  top: 0;
  left: 0;
}
.spacer {
  flex-grow: 1;
}
.error-text {
  font-size: 14px;
  color: rgb(243, 81, 81);
  margin-bottom: 30px;
}
.edit-profile-img-container {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  overflow: hidden;
}

/* blogs css */

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.delete-end{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}



@media (max-width: 460px) {
  .table-fix {
    display: block !important;
  }
}
